import { Menu } from "../model/menu.model"


export var NadudvariMenuItems: Menu[] = [
  {
    headTitle1: "Nádudvari",
  },
  {
    title: "Dashboards",
    icon: "home",
    type: "link",
    path: "/nadudvari-dashboard",
    active: true,
  },
  {
    title: "Products",
    type: "sub",
    icon: "ecommerce",
    children: [
      { path: "/nadudvari-product/list", title: "Products", type: "link", bookmark: true },
      { path: "/nadudvari-jams/list", title: "Lekvárok", type: "link", bookmark: true },
    ],
    active: false,
  },
  {
    title: "menu.resources",
    type: "sub",
    active: false,
    icon: "social",
    children: [
      { path: "nadudvari-resources/machines", title: "menu.resources", type: "link", bookmark: true },
      { path: "nadudvari-resources/machine-locations", title: "Helységek", type: "link", bookmark: true },
      { path: "/shift-planner/template", title: "Műszak sablonok", type: "link", bookmark: true },
    ],
  },
  {
    title: "menu.ingredients", // Alapanyag tervezés
    icon: "learning",
    active: false,
    type: "sub",
    children: [
      { path: "/ingredients/matrix", title: "Készlet tervezés", type: "link", bookmark: true },
      { path: "/ingredients/charts", title: "Statisztikák", type: "link", bookmark: true },
      { path: "/ingredients/transactions", title: "Tranzakciók", type: "link", bookmark: true },
      { path: "/ingredients/list", title: "Alapanyagok", type: "link", bookmark: true },
    ],
  },
  {
    title: "menu.productionPlanning", //Készlet és igények
    icon: "widget",
    active: false,
    type: "sub",
    children: [
      { path: "/demands/matrix", title: "menu.demands", type: "link", bookmark: true },
      { path: "http://10.0.0.34:82", title: "Ütemezés", type: "extLink" },
      { path: "/plans/list", title: "Jelölt tervek", type: "link" },
      { path: "/daily-scheduler/plan-list", title: "Napi tervek", type: "link" },
      { path: "/plans/productionPlan", title: "Gyártási terv", type: "link" },
      { path: "/daily-production/list", title: "Napi gyártás", type: "link" },
      { path: "/daily-scheduler/schedule", title: "Napi ütemezés", type: "link" },
    ],
  },
  {
    title: "Gyártás",
    icon: "social",
    // badgeType: "light-success",
    // badgeValue: "New",
    active: false,
    type: "sub",
    children: [
      { title: "Andon", type: "extLink", path: "http://10.0.0.34:81/nadudvari", active: false },
      { title: "Andon üzenet", type: "link", path: "/nadudvari-message/edit", active: false, icon: "message" },
      { path: "nadudvari-machine-data/production", title: "menu.nadudvar.production", type: "link", bookmark: true, },
      { path: "nadudvari-machine-data/control-changes", title: "menu.nadudvar.control_changes", type: "link", bookmark: true },
      { path: "nadudvari-machine-data/errors", title: "menu.nadudvar.errors", type: "link", bookmark: true },
      { path: "nadudvari-machine-data/shiftlog", title: "menu.nadudvar.shiftlog", type: "link", bookmark: true },
      { title: 'Selejt', icon: 'trash', type: 'link', path: '/scrap/list' },
    ],
  },
  {
    title: "menu.statistics",
    icon: "charts",
    type: "link",
    path: "/nadudvari-statistics",
    // children: [
    //   // { path: "chart/apex", title: "Operation", type: "link" },
    //   { path: "chart/chartjs", title: "Opciók", type: "link" },
    //   // { path: "chart/chartist", title: "Maintanance", type: "link" },
    // ],
  },
  {
    title: "menu.admin_center",
    icon: "social",
    //badgeType: "light-secondary",
    //badgeValue: "In process",
    type: "sub",
    active: false,
    children: [
      { path: "users", title: "menu.users", type: "link" },
      { path: "home/edit", title: "menu.appearance", type: "link" },
      { path: "audit/list", title: "menu.auditLogs", type: "link" },
      { path: "backup/list", title: "menu.backups", type: "link" },
      { path: "health", title: "menu.health", type: "link" },
    ],
  },

  {
    title: "menu.help",
    icon: "support-tickets",
    type: "sub",
    active: false,
    children: [
      {
        title: "menu.support",
        path: "/support",
        icon: "support-tickets",
        type: "link",
      },
      {
        title: "menu.impressum",
        path: "/impressum",
        icon: "faq",
        type: "link",
      },
    ],
  },
];

export var NadudvariTechMenuItems: Menu[] = [
  {
    headTitle1: "Nádudvari",
  },
  {
    title: "Dashboards",
    icon: "home",
    type: "link",
    path: "/nadudvari-dashboard",
    active: true,
  },
  {
    title: "Products",
    type: "sub",
    icon: "ecommerce",
    children: [
      { path: "/nadudvari-product/list", title: "Products", type: "link", bookmark: true },
      { path: "/nadudvari-jams/list", title: "Lekvárok", type: "link", bookmark: true },
    ],
    active: false,
  },
  {
    title: 'menu.resources',
    type: "sub",
    active: false,
    icon: "social",
    children: [
      { path: "/resources/machines", title: 'menu.resources', type: 'link', bookmark: true },
      { path: "/machine-locations", title: 'Helységek', type: 'link', bookmark: true },
      // { path: "https://nadudvari.epicinnolabs.hu:81/nadudvari", title: 'Andon', type: 'extLink', bookmark: true },
      { path: "nadudvari-machine-data/production", title: 'menu.nadudvar.production', type: 'link', bookmark: true },
      { path: "nadudvari-machine-data/control-changes", title: 'menu.nadudvar.control_changes', type: 'link', bookmark: true },
      { path: "nadudvari-machine-data/errors", title: 'menu.nadudvar.errors', type: "link", bookmark: true }
    ]
  },
  {
    title: "Andon",
    type: 'extLink',
    badgeType: "light-success",
    icon: "social",
    path: "http://10.0.0.34:81/nadudvari",
    active: false,
  },
  //   {
  //     title: "Andon",
  //     type: 'extLink',
  //     badgeType: "light-success",
  //     badgeValue: "Új",
  //     icon: "social",
  //     path: "http://10.0.0.34:81/nadudvari",
  //     active: false,
  // },
  {
    title: "menu.help",
    icon: "support-tickets",
    type: "sub",
    active: false,
    children: [
      { title: 'menu.support', path: "/support", icon: 'support-tickets', type: 'link' },
      { title: 'menu.impressum', path: "/impressum", icon: 'faq', type: 'link' },
    ],
  },
];